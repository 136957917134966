import logo from "./logo.webp"

const clientData = {
    client_entity: 10,
    attorney_email: 'Dayes Law Firm',
    attorney_name: 'Dennis Evans',
    attorney_number: '1-800-706-3000',
    attorney_email: 'jeffo3030@yahoo.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_explainer_-_philip_law+(720p).mp4',
    mainColor: '#e80c19',
    secondaryColor: '#114062 ',
    siteLink: 'http://www.phillipslaw.com',
    logo
}

export default clientData